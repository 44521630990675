@import url("https://fonts.googleapis.com/css?family=Inter:400,500,900&display=swap");

#root {
  background: #fff;
}

/* proposal intro */

.proposal_intro .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  @media (min-width: 1024px) {
    gap: 3rem;
  }
}

.proposal_intro,
.proposal_step {
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.proposal_intro.edit-true {
  min-height: inherit;
}

.proposal_step.contract {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.proposal_intro h1 {
  font-size: 3.6rem;
}

.proposal_intro .icon_intro {
  font-size: 7rem;
  transform: rotate(-4deg);
  text-align: center;
}

.highlight {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    #486aff70 50%
  );
  padding: 0 5px;
}

.highlight-alt {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    rgba(45, 156, 219, 0.5) 50%
  );
  padding: 0 5px;
}

.proposal_intro h3 {
  margin-bottom: 1.5rem;
  line-height: 1.8rem;
}

.proposal_intro ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 1.1rem;
  line-height: 1.8rem;
}

.proposal_intro .pull-quote {
  border-left: 1px solid #f2f2f2;
  padding: 0 1.8rem;
  margin-top: 3rem;
  display: none;
}

@media (min-width: 770px) {
  .proposal_intro .pull-quote {
    display: block;
  }
}

.proposal_intro .pull-quote .avatar {
  display: inline-block;
  width: 7rem;
  height: 7rem;
  text-indent: -9999rem;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  margin: 0 0 2rem 0;
}

.proposal_intro .pull-quote h4 {
  line-height: 1.6rem;
  margin-bottom: 0;
}

/* proposal step */

.center_button {
  text-align: center;
}

.center_button .button-big {
  margin: 1rem auto 0 auto;
}

.center_button .button-small {
  margin: 0 auto;
  font-size: 1rem;
}

.center_button small {
  display: block;
  margin-top: 1rem;
  font-family: var(--font-family-label);
  font-size: 0.9rem;
  color: var(--text-color-soft);
}

/* proposal preview */

.proposal_preview_edit {
  position: relative;
  display: none;
}

.proposal_preview_edit #preview {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 580px;
  width: 298px;
  border: none;
  left: -3px;
  position: relative;
  top: 50px;
  z-index: 1;
}

.phone_bg {
  background-image: url("../images/phone.png");
  background-position: top;
  position: absolute;
  top: -12px;
  left: -25px;
  width: 344px;
  height: 693px;
  background-repeat: no-repeat;
  z-index: 0;
  background-size: cover;
  display: none;
}

@media (min-width: 1024px) {
  .proposal_preview_edit,
  .phone_bg {
    display: block;
  }
}

/* tool_tip */

a.info_tooltip {
  width: 16px;
  height: 16px;
  border-radius: 20px;
  font-size: 12px;
  padding: 1px 0 0 0;
  background: rgba(0, 0, 0, 0.15);
  color: #fafafa;
  text-align: center;
  text-decoration: none;
  float: right;
  font-weight: bold;
  position: relative;
  cursor: pointer;
}

a.info_tooltip.left.small {
  float: left;
}

a.info_tooltip.left.small .tooltip_content {
  left: 790%;
}

a.info_tooltip.left .tooltip_content {
  left: 700%;
}

a.info_tooltip.left .tooltip_content::after {
  left: 13%;
}

a.info_tooltip.center .tooltip_content {
  left: 0;
}

a.info_tooltip.center .tooltip_content::after {
  left: 53%;
}

a.info_tooltip.center {
}

label a.info_tooltip,
h4 a.info_tooltip {
  float: none;
  display: inline-block;
}

.cost_group_table_header a.info_tooltip {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}

a.info_tooltip .tooltip_content {
  visibility: hidden;
  width: 18rem;
  background-color: var(--background-color-soft);
  color: var(--text-color);
  font-family: var(--font-family-label);
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  padding: 1rem;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: -770%;
  margin-left: -9rem;
  opacity: 0;
  transition: opacity 0.3s;
}

a.info_tooltip .tooltip_content.small {
  width: 12rem;
}

a.info_tooltip .tooltip_content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 96%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--background-color-soft) transparent transparent transparent;
}

a.info_tooltip:hover .tooltip_content {
  visibility: visible;
  opacity: 1;
}

/* plan table - step 4 */

table.cost_group {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  margin: 0 0 40px 0;
}

table.cost_group .remove-item-td {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  background: #fff;
}

table.cost_group a.remove-item {
  font-size: 0.9rem;
  line-height: 1.2rem;
  min-width: 20px;
  height: 20px;
  width: 20px;
  display: inline-block;
  padding: 0;
  border-radius: 100px;
  z-index: 1;
  left: -30px;
  top: 2px;
  transition: opacity 0.3s;
  background: #828282;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
}
table.cost_group tr.cost_group_table_header a.remove-item {
  top: 10px;
}

table.cost_group a.remove-item:hover {
  visibility: visible;
  opacity: 1;
}

table.cost_group tr:hover a.remove-item {
  visibility: visible;
  opacity: 1;
}

table.cost_group input,
table.cost_group textarea {
  border: 0;
  width: 100%;
  padding: 5px;
}

table.cost_group textarea {
  height: 25px;
  min-width: 107px;
  max-width: 250px;
}

table.cost_group tr.inset_table_heading {
  background: #fafafa;
  font-weight: 500;
}

table.cost_group tr.footer {
  text-align: left;
  font-weight: 500;
}

table.cost_group tr.inset_table_heading td,
table.cost_group tr.footer td:not(.remove-item-td) {
  padding: 5px;
}

table.cost_group tr td {
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  font-size: 0.85rem;
  position: relative;
}

table.cost_group tr:nth-child(1) td {
  border-left: 0;
  border-top: 0;
  border-right: 0;
}

table.cost_group tr:nth-child(1) td input {
  font-size: 1.5rem;
  font-weight: 500;
  width: 95%;
}

table.cost_group tr:nth-child(1) td:nth-child(2):not(.cost-group-input) {
  text-align: right;
  color: #828282;
}

table.cost_group tr:nth-child(2) td:nth-child(3) a {
  color: #828282;
}

table.cost_group tr:nth-child(1) a.info_tooltip {
  margin-top: 0.6rem;
  margin-right: 0.2rem;
}

table.cost_group tr:nth-child(1) td:nth-child(2) {
  border-top: 0;
  border-right: 0;
}

table.cost_group tr td:nth-child(3):not(.cost-group-input) input {
  text-align: right;
}

table.cost_group tr td:nth-child(3):not(.cost-group-input),
table.cost_group tr td:nth-child(4),
table.cost_group tr td:nth-child(5) {
  width: 100px;
}

table.cost_group tr td:nth-child(4) input {
  margin: 0 auto;
  display: block;
  width: auto;
}

table.cost_group tr.footer td:not(.remove-item-td) {
  border-top: 2px solid black;
  border-bottom: 1px solid #eee;
}

table.cost_group tr.footer td:nth-child(3) {
  font-family: var(--font-family-monospace);
}

table.cost_group tr.footer input {
  font-weight: 500;
}

table.cost_group input::-webkit-calendar-picker-indicator {
  opacity: 0.5;
}

/* form input module */
.form label {
  display: block;
  font-family: var(--font-family-form);
  font-weight: 500;
  text-align: left;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form textarea {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0.6em 0.8em;
  font-family: var(--font-family-form);
  color: var(--text-color-soft);
  text-align: left;
  border: 1px solid var(--line-color);
  border-radius: 0.4em;
}

.form input[type="text"]:focus,
.form input[type="email"]:focus,
.form input[type="password"]:focus,
.form textarea:focus {
  color: var(--text-color);
}

.form textarea {
  height: 8rem;
}

.form textarea.contract {
  height: 20rem;
  font-size: 0.8rem;
}

.form input[type="file"] {
  font-size: 0.8rem;
  padding-top: 0.5rem;
}

.form label + input[type] {
  margin-top: 0.2rem;
}

.form small {
  display: block;
  max-width: 25rem;
  margin-top: 0.5rem;
  font-weight: normal;
  font-family: var(--font-family-label);
  font-size: 0.8rem;
  line-height: 1.3;
  text-align: left;
  color: var(--text-color-soft);
}

/* checklist module - copy pasted from viewer, then edited - fix later */
.checklist label {
  display: block;
  position: relative;
  padding: 1.2rem var(--content-padding);
  padding-left: 3rem;
  font-family: var(--font-family-label);
  font-weight: normal;
  line-height: 0.8;
  border-bottom: 1px solid var(--line-color-soft);
}

.checklist label input {
  --left-offset: -0.5rem;
  position: absolute;
  left: calc(var(--content-padding) + var(--left-offset));
}

/* Custom checkbox */
.checklist label input::after {
  --checkbox-size: 1.5rem;

  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  background-color: var(--background-color);
  border: 1px solid var(--highlight-color);
  border-radius: 100%;
}

.checklist label input:checked::after {
  background-color: var(--highlight-color);
}

.container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 0 60px;
  }

  @media (min-width: 1280px) {
    padding: 0 150px;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 35% 65%;
  }
}

.sticky_wrapper {
  padding: 0 0 200px 0;
}

.preview_sticky {
  position: sticky;
  top: 70px;
}

/* Self Clearing Goodness */
.container:after,
.row:after,
.u-cf {
  content: "";
  display: table;
  clear: both;
}

@media print {
  #hubspot-messages-iframe-container {
    display: none !important;
    z-index: -1 !important;
    top: -99999999999px !important;
    right: -99999999999px !important;
  }
}

.hidden {
  display: none !important;
  z-index: -1 !important;
  top: -99999999999px !important;
  right: -99999999999px !important;
}

a[disabled] {
  color: #f2f2f2;
}

.error-message {
  border: 1px solid var(--color-warning);
  background-color: white;
  padding: 0.5rem;
  text-align: center;
  border-radius: 8px;
}
